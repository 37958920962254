import router from 'next/router';
import { getNavigationPagesList, toggleRfqFormModal } from '@/redux/actions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRoute, PATH } from '@/utils/routes';
import ReactHamburger from '@/components/Common/ReactHamburger';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import { deviceWidth } from '@/utils/deviceWidth';
import { IS_DESKTOP } from '@/utils/checkClientDeviceType';
import { getContrastingColor } from '@/utils/getContrastingColor';
import { getCustomHeaderTextColor } from '@/utils/getCustomHeaderTextColor';
import { invertAngleRightIconColor } from '@/utils/invertAngleRightIconColor';
import { showMegaMenu } from '@/utils/showMegaMenu';
import { getStoreType } from '@/utils/getStoreType';
import { CommonHeaderIconsMobile } from '../CommonHeaderIcons';
import { LoginUserIcon } from '@/assets/svgExports/LoginUserIcon';
import { ShoppingBag } from '@/assets/svgExports/ShoppingBag';
import { HeaderInfoIcon } from '@/assets/svgExports/HeaderInfoIcon';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { HeaderHome } from '@/assets/svgExports/HeaderHome';
import { StoreNameComp } from '../StoreNameComponent';
import ShopComponent from '../ShopComponent';
import CommonNavbarText from '../CommonNavbarText/CommonNavbarText';
import useSaveHeaderHeight from '../../../../hooks/useSaveHeaderHeight';
import useIsShopDropDownEnable from '../HeaderUtils/useIsShopDropDownEnable';
import { STORE_CUSTOMIZATION } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
// import MegaMenuDesktopView from '@/components/MegaMenu/MegaMenuDesktopView';
import { getVisibilityForHeader } from '@/utils/logoAndNameHelpers';
import getActualOrDsStoreLogo from '@/utils/getActualOrDsStoreLogo';
import { MenuDrawerMobile } from '../../Drawer/MenuDrawerMobile';
import { MegaMenuDrawerDesktop } from '../../Drawer/MegaMenuDrawerDesktop';
import getNavList from '@/utils/getNavList';

const Header12 = (props) => {
  const isDesktop = deviceWidth > 786;
  const ref = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState({
    openShop: false,
    openMore: false,
    openDropdown: false,
  });
  const [openMegaMenu, setOpenMegaMenu] = useState({
    desktop: false,
    mobile: false,
  });
  const [, setOpenCollections] = useState(false);

  const { items, user } = useSelector((state) => ({
    items: state.cartReducer.items,
    user: state.userReducer,
  }));
  const { storeData, navigationList, collections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    collections: state.catalogReducer.collections,
    navigationList: state.additionalPagesReducer.navigationList,
  }));

  const storeLogo = getActualOrDsStoreLogo(storeData);
  const navItemsState = getNavList(navigationList);
  const headerBgColor = props?.config?.color_scheme?.bg_color || '#ffffff';
  const isStoreLogoVisible =
    props?.config?.hidden_components?.store_logo_visible || false;

  const headerRef = useRef();
  const isShopDropDownEnable = useIsShopDropDownEnable();

  useSaveHeaderHeight(headerRef);

  useEffect(() => {
    if (!open.openDropdown) setOpenCollections(false);
  }, [open.openDropdown]);

  useEffect(() => {
    if (storeData?.store_id && !navigationList.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id, navigationList]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (isDesktop) {
      if (ref.current && ref.current.contains(e.target)) {
        return;
      }
      setOpen((state) => ({ ...state, openShop: false, openMore: false }));
    }
  };

  const menuItemClick = (nav) => {
    if (openMegaMenu?.desktop || openMegaMenu?.mobile) {
      setOpenMegaMenu(() => ({
        desktop: false,
        mobile: false,
      }));
    }

    if (nav?.page_type === 'blank_page' && nav?.is_external_url) {
      window.open(nav.slug_url, '_blank');
      return;
    }

    if (nav.slug_url === 'shop') {
      if (document.querySelector('#CollectionCategoryProductWidget')) {
        document.querySelector('#CollectionCategoryProductWidget').scrollIntoView();
      } else {
        router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));
      }
    } else if (nav.slug_url === 'subNavList') {
      setOpen((state) => ({
        ...state,
        openMore: !open.openMore,
      }));
    } else {
      if (nav.is_editable) {
        router.push(
          getRoute(PATH.additionalPage(nav.slug_url), storeData?.store_info?.domain)
        );
      } else {
        router.push(getRoute(nav.slug_url, storeData?.store_info?.domain));
      }
    }
  };

  const navSubList = (nav) => {
    return (
      <div className="cfe-dropdown-nav" ref={ref}>
        <div className="cfe-dropdown-nav__items">
          {nav.map((n) => (
            <div
              className="cfe-dropdown-nav__item"
              key={n.page_id}
              onClick={() => menuItemClick(n)}
            >
              {n.page_name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleShopClick = (page, dropdownNav) => {
    if (showMegaMenu()) {
      IS_DESKTOP
        ? setOpenMegaMenu(() => ({
            mobile: false,
            desktop: !openMegaMenu.desktop,
          }))
        : setOpenMegaMenu(() => ({
            desktop: false,
            mobile: !openMegaMenu.mobile,
          }));
    } else {
      if (collections?.length) {
        if (dropdownNav) {
          setOpenCollections(true);
          setOpen((state) => ({ ...state, openShop: !state.openShop }));
        } else {
          menuItemClick(page);
        }
      } else {
        !dropdownNav && menuItemClick(page);
        open.openShop
          ? setOpen((state) => ({ ...state, openShop: false }))
          : setOpen((state) => ({ ...state, openShop: true, openMore: false }));
      }
    }
  };

  const closeShopDropdown = useCallback(
    () => setOpen((state) => ({ ...state, openShop: false, openDropdown: false })),
    []
  );

  const renderNavType = (page, dropdownNav = false) => {
    switch (page.slug_url) {
      case '':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative tw-select-none`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <div className="mr3 visible-mobile">
                  <HeaderHome
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                  />
                </div>
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      case 'shop':
        return (
          <ShopComponent
            ref={ref}
            page={page}
            dropdownNav={isShopDropDownEnable}
            handleShopClick={handleShopClick}
            setOpenMegaMenu={setOpenMegaMenu}
            open={open}
            closeShopDropdown={closeShopDropdown}
            bgColor={headerBgColor}
            showMegaMenuDesktop={openMegaMenu.desktop}
          />
        );
      case 'subNavList':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative tw-select-none`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <CommonNavbarText page={{ page_name: 'More' }} bgColor={headerBgColor} />
              </div>
              {page?.subNavs?.length > 0 && (
                <img
                  className={invertAngleRightIconColor(headerBgColor)}
                  src="/assets/images/angleright.png"
                  alt="dropdown button"
                  style={{
                    transform: 'rotate(90deg)',
                    width: 7,
                    marginLeft: dropdownNav ? '0px' : '8px',
                  }}
                />
              )}
            </div>
            {open.openMore && navSubList(page.subNavs)}
          </li>
        );
      case 'about-us':
        return (
          <li
            key={page.page_id}
            className={`menu-item relative tw-select-none`}
            onClick={() => menuItemClick(page)}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center" style={{ flex: '1 1 auto' }}>
                <div className="mr3 visible-mobile">
                  <HeaderInfoIcon
                    color={
                      storeData?.theme?.colors?.primary_color !== '#ffffff'
                        ? '#000000'
                        : '#ffffff'
                    }
                  />
                </div>
                <CommonNavbarText bgColor={headerBgColor} page={page} />
              </div>
            </div>
          </li>
        );
      default:
        return (
          <li
            key={page.page_id}
            className={`menu-item tw-select-none ${getCustomHeaderTextColor(headerBgColor)}`}
            onClick={() => menuItemClick(page)}
          >
            {page.page_name}
          </li>
        );
    }
  };

  const toggleDropdown = () => {
    setOpen((state) => ({
      ...state,
      openDropdown: !state.openDropdown,
    }));
  };

  const clickForRoute = (route) => {
    router.push(getRoute(route, storeData?.store_info?.domain));
  };

  const leftSection = () =>
    isDesktop &&
    (props?.config?.hidden_components?.store_logo_visible ||
      getVisibilityForHeader(storeData?.theme?.store_logo_status)) ? (
      <section
        style={{ width: '72px', alignSelf: 'baseline' }}
        className="visible-desktop-flex mb5 tw-cursor-pointer"
        onClick={() => clickForRoute('')}
      >
        <img
          className={storeLogo ? 'logo_image_desktop' : 'dn'}
          src={storeLogo}
          alt="store logo"
        />
      </section>
    ) : (
      <div>
        <section className="visible-desktop-flex"></section>
        <div className="visible-mobile-flex w-100">
          <ReactHamburger
            open={open.openDropdown}
            setOpen={toggleDropdown}
            theme={getContrastingColor(headerBgColor)}
          />
        </div>
      </div>
    );

  const middleSection = () => {
    return <StoreNameComp customClass="visible-desktop justify-center tc" />;
  };

  const handleCartClick = () => {
    if (getStoreType(storeData) === STORE_CUSTOMIZATION.B2B) {
      dispatch(toggleRfqFormModal());
      return;
    }
    clickForRoute(PATH.CART);
  };

  return (
    <header
      ref={headerRef}
      className="header12 collection-header tw-font-dmSans"
      style={{
        background: headerBgColor,
        color: getContrastingColor(headerBgColor),
      }}
    >
      <div className="header12__top-component">
        {leftSection()}
        <div>
          {middleSection()}
          <div className="header12__bottom-component visible-desktop-block">
            <div className="visible-desktop-flex">
              {navItemsState?.length > 0 && (
                <>
                  <ul className="header12__bottom-component--menu">
                    {navItemsState?.map((nav) => renderNavType(nav, false))}
                    <li className="menu-item" style={{ paddingBottom: 15 }}>
                      <SearchIcon
                        className="mr3"
                        width="23"
                        height="23"
                        color={getContrastingColor(headerBgColor)}
                        onClick={() => clickForRoute(PATH.SEARCH)}
                      />
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <section className="visible-desktop flex items-center justify-end icon-set">
            <span>
              <LoginComponent headerBgColor={headerBgColor}>
                <LoginUserIcon
                  width="23"
                  height="23"
                  color={getContrastingColor(headerBgColor)}
                />
                {user?.isLogin ? (
                  <img
                    alt="dropdown button"
                    src="/assets/images/down-arrow-thin.svg"
                    className={`ml2 arrow-icon ${invertAngleRightIconColor(
                      headerBgColor
                    )}`}
                  />
                ) : (
                  ''
                )}
              </LoginComponent>
            </span>
            <span onClick={handleCartClick}>
              <ShoppingBag
                width="25"
                height="25"
                color={getContrastingColor(headerBgColor)}
              />
              {items?.length > 0 && (
                <span
                  className={`cart-items absolute z-2 ${invertAngleRightIconColor(
                    headerBgColor
                  )}`}
                >
                  {items.length}
                </span>
              )}
            </span>
          </section>
          <div className="visible-mobile">
            <CommonHeaderIconsMobile
              color={getContrastingColor(headerBgColor)}
              headerName="header12"
              isStoreLogoVisible={isStoreLogoVisible}
            />
          </div>
        </div>
      </div>

      {openMegaMenu.desktop && (
        <MegaMenuDrawerDesktop
          open={openMegaMenu.desktop}
          onClose={() => setOpenMegaMenu({ desktop: false, mobile: false })}
        />
      )}
      <MenuDrawerMobile isVisible={open.openDropdown} closeDrawer={toggleDropdown} />
      {/* {open.openDropdown && (
        <CollectionHeaderDropdown
          renderNavType={renderNavType}
          navItemsState={navItemsState}
          openCollections={openCollections}
          goBack={() => setOpenCollections(false)}
          collectionListType={props?.config?.name || null}
          toggleDropdown={toggleDropdown}
        />
      )} */}
    </header>
  );
};

export default Header12;
